module.exports.AppAddress = Object.freeze({
	ios: "verifiedcvapp:",
});

module.exports.DetailType = Object.freeze({
	en: {
		0: "Field Event",
		1: "Online Event",
		2: "Class Course",
		3: "Online Course",
		4: "Webinar",
		5: "Achievement",
		6: "License",
		7: "Appreciation",
		8: "Promotional Course",
		9: "Membership",
		10: "Graduation",
	},
	tr: {
		0: "Canlı Organizasyon",
		1: "Çevrimiçi Organizasyon",
		2: "Sınıf Dersi",
		3: "Çevrimiçi Ders",
		4: "Web Semineri",
		5: "Başarı",
		6: "Lisans",
		7: "Teşekkür",
		8: "Promosyon Kursu",
		9: "Üyelik",
		10: "Mezuniyet",
	},
});

module.exports.UserRoles = Object.freeze({
	inactive: "ROLE0",
	active: "ROLE1",
	imported: "ROLE2",
	deleted: "ROLE7",
});

module.exports.DetailLevel = Object.freeze({
	en: {
		0: "Beginner",
		1: "Intermediate",
		2: "Advanced",
		3: "Experienced",
		4: "Professional",
	},
	tr: {
		0: "Başlangıç",
		1: "Orta Düzey",
		2: "İleri Düzey",
		3: "Deneyimli",
		4: "Profesyonel",
	},
});

module.exports.DetailDuration = Object.freeze({
	en: {
		0: "Less than one day",
		1: "1 to 7 days",
		2: "1 to 4 weeks",
		3: "1 to 12 months",
		5: "Not Applicable",
	},
	tr: {
		0: "1 günden az",
		1: "1-7 gün",
		2: "1-4 hafta",
		3: "1-12 ay",
		5: "Belirtilmemiş",
	},
});

module.exports.EvidenceTypes = Object.freeze({
	en: {
		0: "Video",
		1: "Belge",
		2: "Not",
		3: "Referans",
		4: "LinkedIn Profili",
	},
	tr: {
		0: "Video",
		1: "Document",
		2: "Grade",
		3: "Reference",
		4: "LinkedIn Profili",
	},
});

module.exports.DetailCost = Object.freeze({
	en: {
		0: "Free",
		1: "Paid",
	},
	tr: {
		0: "Ücretsiz",
		1: "Ücretli",
	},
});

module.exports.SocialMedia = Object.freeze({
	1: "phone",
	2: "mail",
	3: "website",
	4: "twitter",
	5: "facebook",
	6: "linkedin",
	7: "instagram",
	8: "apple",
});

module.exports.SkillLevels = Object.freeze({
	en: {
		0: "Rookie",
		1: "Beginner",
		2: "Intermdiate",
		3: "Professional",
		4: "Advanced",
	},
	tr: {
		0: "Rookie",
		1: "Beginner",
		2: "Intermdiate",
		3: "Professional",
		4: "Advanced",
	},
});

module.exports.WorkTypes = Object.freeze({
	en: {
		1: "Full time",
		2: "Part time",
		3: "Own job",
		4: "Independet work",
		5: "Contract Worker",
		6: "Intern",
		7: "Apprentice",
	},
	tr: {
		1: "Tam zamanlı",
		2: "Yarı zamanlı",
		3: "İş sahibi",
		4: "Serbest çalışan",
		5: "Sözleşmeli çalışan",
		6: "Stajyer",
		7: "Çırak",
	},
});

module.exports.DegreeTypes = Object.freeze({
	en: {
		1: "Associate Degree",
		2: "Bachelor's Degree",
		3: "Master Degree",
		4: "Doctoral Degree",
	},
	tr: {
		1: "Ön Lisans",
		2: "Lisans",
		3: "Yüksek Lİsans",
		4: "Doktora",
	},
});

module.exports.VolunteerType = Object.freeze({
	en: {
		1: "Animal protection",
		2: "Art and Culture",
		3: "Children",
		4: "Human rights and social actions",
		5: "Economic empowerment",
		6: "Education",
		7: "Environment",
		8: "Health",
		9: "Human rights",
		10: "Disaster and humanitarian aid",
		11: "Politics",
		12: "Fight against poverty",
		13: "Science and technology",
		14: "Social services",
	},
	tr: {
		1: "Hayvanları koruma",
		2: "Kültür ve Sanat",
		3: "Çocuklar",
		4: "İnsan hakları ve sosyal eylemler",
		5: "Ekonomik güçlendirme",
		6: "Eğitim",
		7: "Çevre",
		8: "Sağlık",
		9: "İnsan hakları",
		10: "Felaket ve insani yardım",
		11: "Politika",
		12: "Yoksullukla mücadele",
		13: "Bilim ve teknoloji",
		14: "Sosyal hizmetler",
	},
});

module.exports.LanguageLevels = Object.freeze({
	en: {
		1: "Beginner",
		2: "Limited Work",
		3: "Professional Work",
		4: "Full Professional",
		5: "Mother Tongue",
	},
	tr: {
		1: "Başlangıç",
		2: "Sınırlı çalışma",
		3: "Profesyonel çalışma",
		4: "Tam profesyonel",
		5: "Ana dil veya ikinci dil",
	},
});

module.exports.AdEventTypes = Object.freeze({
	click: 1,
	view: 2,
});

module.exports.EventActions = Object.freeze({
	ProfileView: 1,
	CredentialView: 2,
	ExperienceView: 3,
	EducationView: 4,
	Search: 5,
	VerifyCredential: 6, // From Verified.cv,
	ContactIssuer: 7, // Deprecated,
	SourcePageView: 8,
	SourceFollow: 9,
	ListPageView: 10,
	ListFollow: 11,
	ListContentEdit: 12,
	SkillFollow: 13,
	SkillPageView: 14,
	ProfileFollow: 15,
	NotificationClick: 16,
	ScreenView: 17,
	AppOpen: 18,
	IssuerContact: 19, // From Verified.cv
	IssuerWebsiteVisit: 20, // From Verified.cv
	LinkedinTrafficGain: 21, // From Verified.cv
	FacebookTrafficGain: 22, // From Verified.cv
	InstagramTrafficGain: 23, // From Verified.cv
	TwitterTrafficGain: 24, // From Verified.cv
});

module.exports.defaultImages = Object.freeze([
	"https://storage.googleapis.com/verified-storage/no_data_fallbacks/fallback-01.png",
	"https://storage.googleapis.com/verified-storage/no_data_fallbacks/fallback-02.png",
	"https://storage.googleapis.com/verified-storage/no_data_fallbacks/fallback-03.png",
	"https://storage.googleapis.com/verified-storage/no_data_fallbacks/fallback-04.png",
]);

module.exports.IssuerStatuses = Object.freeze({
	0: "not-verified",
	1: "verified",
});

module.exports.SocialTypes = Object.freeze({
	Instagram: 0,
	Facebook: 1,
	Twitter: 2,
	Linkedin: 3,
});

module.exports.ProfileSortOptions = Object.freeze({
	newOld: 1,
	oldNew: 2,
	az: 3,
	za: 4,
});

module.exports.IssuerProfileCampaignSortOptions = Object.freeze({
	CampaignCountDESC: 0,
	CampaignCountASC: 1,
	EventTypeASC: 2,
	EventTypeDESC: 3,
});

module.exports.IssuerProfileCollectionSortOptions = Object.freeze({
	CampaignCountDESC: 0,
	CampaignCountASC: 1,
	CollectionTitleASC: 2,
	CollectionTitleDESC: 3,
});

module.exports.IssuerProfileRecipientSortOptions = Object.freeze({
	AlphabeticalASC: 0,
	AlphabeticalDESC: 1,
	CredentailCountASC: 2,
	CredentialCountDESC: 3,
});

module.exports.InputSuffixIcon = Object.freeze({
	success: "success",
	error: "error",
	info: "info",
	search: "search",
});

module.exports.StorageKeys = Object.freeze({
	user_token: "__tkn__",
	user_id: "__uid__",
	user: "__u__",
	usr_certificate_ids: "__CSID__",
	usr_badge_ids: "___BDID__",
	current_lang: "__cLang__",
	error_logs_to_send: "___errlg__",
	ads_log_id: "__adslg__",
	app_env: "__aenv__",
});

module.exports.ENV_VALUES = Object.freeze({
	development: "development",
	production: "production",
});

module.exports.VELOCITY_CREDENTIAL_CATEGORIES = Object.freeze({
	Identity: 1,
	Education: 2,
	Experience: 3,
	Badges: 4,
	Certificates: 5,
	Training: 6,
	Awards: 7,
	Assesments: 8,
	Health: 9,
});

module.exports.googleAuthClientKey =
	"358320551922-5gpt7fqiuohtsn0vqn2i4cbuff05fbh7.apps.googleusercontent.com";

module.exports.EmailRegex =
	/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
